<template>
  <el-main>
    <el-form label-width="140px">
      <el-form-item label="冻结押金比例：">
        <el-input v-model="freeze_order_ratio">
           <template slot="append">%</template>
        </el-input>
        <p>备注：如果权益会员不交押金，小于基础金额的订单会冻结金额的比例</p>
      </el-form-item>
      <el-form-item label="押金金额：">
        <div class="setting-list">
          <div class="item" v-for="(item, index) in setting" :key="index">
            <div class="order-price">
              <span>订单金额</span>
              <el-input disabled="true" v-model="item.num"></el-input>
              ~
              <el-input v-model="item.figure"></el-input>
            </div>
            <div class="deposit">
              <span>押金金额</span>
              <el-input v-model="item.deposit_amount"></el-input>
              元
            </div>
            <i class="el-icon-circle-close" @click="setting.splice(index, 1)" v-if="index != 0 && setting.length - 1 == index"></i>
          </div>
        </div>
        <el-button class="el-icon-plus" type="text" @click="addDeposit">添加档位</el-button>
        <p>备注：如果不添加，则代表大于等于此金额的押金</p>
      </el-form-item>
    </el-form>
    <Preservation @preservation="submit"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import _ from 'lodash';
export default {
  components: {
    Preservation,
  },
  data() {
    return {
      freeze_order_ratio: '',
      setting: [
        {
          num: '0',
          figure: '',
          deposit_amount: '',
        },
      ],
    };
  },
  created(){
    this.$axios.post(this.$api.samecity.depositInfo).then(res=>{
      if(res.code == 0){
        this.freeze_order_ratio = res.result.freeze_order_ratio;
        this.setting = res.result.settings;
      }
    })
  },
  methods: {
    addDeposit() {
      let setting = this.setting;
      let re = /^[0-9]+.?[0-9]*$/;
      for (let i in setting) {
        if (!re.test(setting[i].figure)) return this.$message.warning('请填写正确的数字');
        if (Number(setting[i].figure) <= Number(setting[i].num)) return this.$message.warning('请填写正确的订单金额范围');
      }
      setting.push({
        num: setting[setting.length - 1].figure,
        figure: '',
        deposit_amount: '',
      });
    },
    submit() {
      let setting = _.cloneDeep(this.setting);
      for (let i in setting) {
        delete setting[i].num;
        if (!setting[i].deposit_amount) return this.$message.warning('请填写押金金额');
      }
      if (!this.freeze_order_ratio) return this.$message.warning('请填写冻结押金比例');

      this.$axios
        .post(this.$api.samecity.depositSet, {
          freeze_order_ratio: this.freeze_order_ratio,
          setting: this.setting,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('保存成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  height: 100%;
  .el-form {
    width: 820px;
    p {
      color: #999;
      font-size: 13px;
    }
    .setting-list {
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        position: relative;
        .order-price {
          display: flex;
          align-items: center;
          margin-right: 20px;
          span {
            margin-right: 10px;
          }
          .el-input:nth-child(2) {
            width: 160px;
            margin-right: 5px;
          }
          .el-input:nth-child(3) {
            width: 120px;
            margin-left: 5px;
          }
        }
        .deposit {
          display: flex;
          align-items: center;
          span {
            margin-right: 10px;
          }
          .el-input {
            width: 170px;
            margin-right: 10px;
          }
        }
        i {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          z-index: 2;
          font-size: 25px;
        }
      }
    }
  }
}
</style>